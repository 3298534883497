import { supportsAVIF } from '../utils'
const template = document.createElement('template')
template.innerHTML = `
    <style>
        :host {
            display: block;
            --levelOne: translateZ(3rem);
            --levelTwo: translateZ(6rem);
            --levelThree: translateZ(9rem);
            --cardTitleFontSize: 2rem;
            --cardSubtitleFontSize: 0.8rem;
            --cardFrontBorderOffset: 1em;
            --emailColor: #535c68;
            --emailHoverColor: white;
            --emailHoverBackgroundColor: black;
        }
        *, *::before, *::after {
            margin: 0;
        }
        a {
            display: inline-flex;
            box-sizing: border-box;
            width: 6rem;
            height: 6rem;
            padding: 1.5rem;
            color: inherit;
            font-size: 3rem;
            cursor: pointer;
            text-decoration: none;
            transform: var(--levelTwo);
            transition: color 0.5s linear;
        }
        a:hover {
            color: var(--emailHoverColor);
        }
        a::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            background-color: var(--emailHoverBackgroundColor);
            z-index: -1;
            transform: scale(0);
            transition: transform 0.3s cubic-bezier(0.95, 0.32, 0.37, 1.21);
        }
        a:hover::after {
            transform: scale(1);
        }
        .buttonContainer {
            transform: var(--levelTwo);
        }
        .card {
            position: relative;
        }
        .cardBack {
            row-gap: 50px;
            padding: 0 10%;
            color: var(--emailColor);
            background-color: white;
            transform: rotateY(0.5turn);
        }
        .cardBody {
            transform: var(--levelTwo);
        }
        .cardContent {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            text-align: center;
            transform-style: preserve-3d;
            transition: transform 3s;
        }
        .card:hover > .cardContent {
            transform: rotateY(0.5turn);
        }
        .cardFront {
            grid-row-gap: 3vw;
            color: white;
            background-color: #333333;
            background-size: cover;
            background-blend-mode: overlay;
        }
        .cardFront::after {
            content: '';
            position: absolute;
            top: var(--cardFrontBorderOffset);
            right: var(--cardFrontBorderOffset);
            bottom: var(--cardFrontBorderOffset);
            left: var(--cardFrontBorderOffset);
            border: 3px solid currentColor;
            transform: var(--levelOne);
        }
        .cardBack, .cardFront {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: grid;
            align-content: center;
            padding: calc(var(--cardFrontBorderOffset) + 3px);
            transform-style: preserve-3d;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
        }
        .cardBack > *, .cardFront  > * {
            line-height: 1.6;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .cardSubtitle {
            font-size: var(--cardSubtitleFontSize);
            opacity: 0.7;
            letter-spacing: 2px;
            transform: var(--levelTwo);
        }
        .cardTitle {
            order: 2;
            font-size: var(--cardTitleFontSize);
            transform: var(--levelThree);
        }
        @media screen and (min-width: 600px) {
            :host {
                --cardSubtitleFontSize: 1rem;
                --cardTitleFontSize: 3.5rem;
            }
            .cardFront {
                grid-row-gap: 20px;
            }
            .cardSubtitle {
                letter-spacing: 4px;
            }
        }
    </style>
    <div class="card">
        <div class="cardContent">
            <div class="cardFront">
                <h3 class="cardTitle"></h3>
                <p class="cardSubtitle"></p>
            </div> 
            <div class="cardBack">
                <p class="cardBody"></p>
                <div class="buttonContainer">
                    <a href="mailto:torresnick272@gmail.com" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 40 330 250" fill="currentColor">
                            <path d="M173.871,177.097c-2.641,1.936-5.756,2.903-8.87,2.903c-3.116,0-6.23-0.967-8.871-2.903L30,84.602L0.001,62.603L0,275.001c0.001,8.284,6.716,15,15,15L315.001,290c8.285,0,15-6.716,15-14.999V62.602l-30.001,22L173.871,177.097z"/>
                            <polygon points="165.001,146.4 310.087,40.001 19.911,40"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
`

class ContactCard extends HTMLElement {

    constructor() {
        super()

        //Enable shadow DOM
        this.attachShadow({ mode: 'open' })
        this.shadowRoot.appendChild(template.content.cloneNode(true))

        //Size card height according to width
        this.shadowRoot.lastElementChild.style.height = `${this.shadowRoot.lastElementChild.offsetWidth + 100}px`
        const o = new ResizeObserver(() => this.shadowRoot.lastElementChild.style.height = `${this.shadowRoot.lastElementChild.offsetWidth + 100}px`)
        o.observe(this.shadowRoot.lastElementChild)

        //Insert card text
        this.shadowRoot.querySelector('.cardTitle').textContent = this.firstElementChild.textContent
        this.shadowRoot.querySelector('.cardSubtitle').textContent = this.firstElementChild.nextElementSibling.textContent
        this.shadowRoot.querySelector('.cardBody').textContent = this.lastElementChild.textContent

        //Insert background image
        supportsAVIF(() => this.shadowRoot.querySelector('.cardFront').style.backgroundImage = `url(${this.getAttribute('image')}.avif)`, () => this.shadowRoot.querySelector('.cardFront').style.backgroundImage = `url(${this.getAttribute('image')}.webp)`)

        this.innerHTML = null
    }

}

window.customElements.define('contact-card', ContactCard)