import * as Three from 'three'
import Particles from './Particles'
import configFile from './config.js'
import gsap from 'gsap'
import { isMobile } from '../utils.js'


export default class WebGLView {

    #button
    #buttonClicked = false
    #camera
    #canvas
    #clock
    #isMobile
    orientationUpdate = false
    #particles
    #renderer
    #scene
    #text

    constructor(canvas, button, text) {
        this.#button = button
        this.#canvas = canvas
        this.#text = text
        this.#isMobile = isMobile()
        this.#initThree()
        this.#initParticles()
    }

    #fitCameraToParticles() {
        const orbAspectRatio = 1
        const fov = 45
        this.#renderer.setSize(this.#canvas.parentElement.offsetWidth, window.innerHeight, false)
        this.#camera.aspect = this.#canvas.parentElement.offsetWidth / window.innerHeight

        
        if(this.#camera.aspect > orbAspectRatio) {
            this.#camera.fov = fov
        } else {
            const cameraHeight = Math.tan((fov / 2) * Math.PI / 180)
            const ratio = this.#camera.aspect / orbAspectRatio
            const newCameraHeight = cameraHeight / ratio
            this.#camera.fov = Math.atan(newCameraHeight) * (180 / Math.PI) * 2
        }
        this.#camera.updateProjectionMatrix()
    }

    #initParticles() {
        window.addEventListener('appReady', this.#populateScreen.bind(this))
        this.#particles = new Particles(this.#scene, '/images/javascript.png')
        this.#button.addEventListener('click', () => {
            if(this.#buttonClicked) document.getElementById('about').scrollIntoView({ behavior: 'smooth' })
            else {
                this.#buttonClicked = true
                this.#particles.assembleImage(function() { document.getElementById('about').scrollIntoView({ behavior: 'smooth' }) })
            }
        })
    }
    
    #initThree() {
        this.#renderer = new Three.WebGLRenderer({
            canvas: this.#canvas,
            antialias: true
        })
        this.#renderer.setSize(this.#canvas.parentElement.offsetWidth, window.innerHeight, false)
        this.#renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        this.#renderer.outputEncoding = Three.sRGBEncoding
        this.#renderer.setClearColor('#292929')
        this.#camera = new Three.PerspectiveCamera(45, this.#canvas.parentElement.offsetWidth / window.innerHeight, 0.01, 1000)
        this.#camera.position.set(-0.5, 0, 230)
        window.camera = this.#camera
        this.#scene = new Three.Scene().add(this.#camera)
        this.#clock = new Three.Clock()
    }

    #populateScreen() {
        this.#fitCameraToParticles()
        gsap.to(this.#canvas.parentElement, {
            duration: configFile.orbLeftTranslate,
            delay: configFile.orbLeftTranslateDelay,
            ease: 'power3.out',
            right: 0,
            onComplete: () => {
                gsap.to(this.#text, {
                    right: 0,
                    duration: configFile.welcomeTextAnimationTime,
                    ease: 'back.out(2)'
                })
                gsap.to(this.#button.parentElement, {
                    delay: configFile.welcomeButtonAnimationDelay,
                    duration: configFile.welcomeButtonAnimationTime,
                    ease: 'power3.out',
                    opacity: 1,
                    transform: 'rotateZ(-10deg)'
                })
            }
        })
    }

    draw = () => this.#renderer.render(this.#scene, this.#camera)

    resize() {
        //Don't resize fullscreen mobile browsers
        if(this.orientationUpdate || !(this.#isMobile && window.innerWidth === screen.availWidth)) {
            this.orientationUpdate = false
            this.#renderer.setPixelRatio(Math.min(2, window.devicePixelRatio))
            this.#fitCameraToParticles()
        }
    }

    update() {
        if(this.#particles) this.#particles.update(this.#clock.getElapsedTime())
    }

}