import { supportsAVIF } from '../utils.js'

const template = document.createElement('template')
template.innerHTML = `
    <style>
        :host {
            display: block;
        }
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            height: 100%;
            width: 100%;
            border-radius: inherit;
            object-fit: cover;
            filter: grayscale(100%);
            -webkit-filter: grayscale(100%);
            opacity: 0.5;
            transition: opacity 0.5s, filter 0.5s, -webkit-filter 0.5s;
        }
        img:hover {
            opacity: 1;
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
        }
        .container {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            column-gap: 10px;
            align-items: center;
            line-height: 1.3;
        }
        .container.after::after {
            width: 0;
            height: 0;
        }
        .container > div {
            grid-row: 1 / -1;
        }
        .description {
            border-radius: 10px;
            color: white;
        }
        .linksContainer {
            margin: 10px 0;
            max-height: 20px;
        }
        .linksContainer > a {
            display: inline-block;
            width: 20px;
            max-height: inherit;
            color: white;
            transition: color 0.5s;
        }
        .linksContainer > a:not(:last-child) {
            margin-right: 20px;
        }
        .linksContainer > a:hover {
            color: yellow;
        }
        .linksContainer svg {
            height: 100%;
        }
        .projDesc {
            grid-column: 1 / -1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            width: 100%;
            padding: 30px;
            z-index: 1;
        }
        .projImage {
            grid-column: 1 / -1;
            width: 100%;
            height: 100%;
            opacity: 0.3;
            border-radius: 5px;
            background-color: yellow;
        }
        .projectHeading {
            margin: 10px 0;
            color: yellow;
            font-size: 14px;
        }
        .projectName {
            margin: 0 0 20px 0;
            color: white;
            font-size: clamp(24px, 5vw, 28px);
        }
        .softwareContainer {
            margin: 10px 0;
            color: white;
            pointer-events: none;
        }
        .softwareContainer > span {
            display: inline-block;
            pointer-events: auto;
        }
        .softwareContainer > span:not(:last-child) {
            padding: 0 15px 0 0;
        }
        @media screen and (min-width: 480px) {
            .projDesc {
                padding: 40px;
            }
        }
        @media screen and (min-width: 600px) {
            .description {
                padding: 20px 0;
            }
            .projDesc {
                padding: 40px 40px 30px 40px;
            }
            .projDesc > * {
                z-index: 2;
            }
            .projectName {
                margin-bottom: 10px;
            }
            .softwareContainer {
                margin: 10px 0;
            }
            .softwareContainer > span:not(:last-child) {
                padding: 0 30px 0 0;
            }
        }
        @media screen and (min-width: 768px) {
            img {
                min-height: 270px;
            }
            .container.after::after {
                grid-column: 1 / -1;
                content: '';
                width: 75%;
                height: 1px;
                background-color: white;
            }
            .container.after.reverse::after {
                justify-self: end;
            }
            .description {
                padding: 25px;
                background-color: white;
                color: #292929;
                z-index: 2;
            }
            .projDesc {
                grid-column: 1 / 9;
                padding:  0;
            }
            .projDesc.reverse {
                grid-column: 7 / -1;
                text-align: right;
            }
            .projDesc > .description {
                text-align: left;
            }
            .projImage {
                grid-column: 6 / -1;
                width: unset;
                height: unset;
                opacity: unset;
            }
            .projImage.reverse {
                grid-column: 1 / 8;
                justify-self: start;
            }
            .softwareContainer {
                margin: 25px 0 10px 0;
                z-index: 2;
            }
            .softwareContainer.reverse > span:first-child {
                padding: 0;
            }
        }
        @media screen and (min-width: 1024px) {
            .projDesc {
                grid-column: 1 / 7;
            }
            .projDesc.reverse {
                grid-column: 7 / -1;
            }
            .projImage {
                grid-column: 6 / -1;
            }
            .projImage.reverse {
                grid-column: 1 / 8;
            }
            .softwareContainer:not(.reverse) > span:not(:last-child) {
                padding: 0 30px 0 0;
            }
            .softwareContainer.reverse > span:not(:first-child) {
                padding: 0 0 0 30px;
            }
        }
    </style>
    <div class="container">
        <div class="projDesc">
            <p class="projectHeading">Recent Project</p>
            <h3 class="projectName"></h3>
            <div class="description"></div>
            <div class="softwareContainer"></div>
            <div class="linksContainer"></div>
        </div>
        <div class="projImage">
            <img alt="Broken link">
        </div>
    </div>
`

class FeaturedProjectCard extends HTMLElement {

    constructor() {
        super()
        
        //Enable shadow DOM
        this.attachShadow({ mode: 'open' })
        this.shadowRoot.appendChild(template.content.cloneNode(true))

        //Insert project image
        supportsAVIF(() => this.shadowRoot.querySelector('img').src = this.getAttribute('image').concat('.avif'), () => this.shadowRoot.querySelector('img').src = this.getAttribute('image').concat('.webp'))

        //Insert project heading
        this.shadowRoot.querySelector('.projectName').textContent = this.getAttribute('heading')
        
        //Insert project description
        this.shadowRoot.querySelector('.description').textContent = this.querySelector('article').textContent
        
        //Insert software array
        const softwareItems = this.querySelector('ul[software]').children
        if(softwareItems) {
            const softwareContainer = this.shadowRoot.querySelector('.softwareContainer')
            for(let i = 0; i < softwareItems.length; i++)
                softwareContainer.innerHTML += `<span>${softwareItems[i].textContent}</span>`
        }

        //Insert link array
        const links = this.querySelector('ul[links]').children
        if(links) {
            const linksContainer = this.shadowRoot.querySelector('.linksContainer')
            for(let i = 0; i < links.length; i++) {
                const blankTarget = links[i].hasAttribute('newTab') ? ' target="_blank" ' : ''
                linksContainer.innerHTML += `<a ${blankTarget}href="${links[i].getAttribute('target')}">${links[i].innerHTML}</a>`
            }
        }

        //Handle last child
        if(this !== this.parentElement.lastElementChild) this.shadowRoot.lastElementChild.classList.add('after')

        //Handle reverse attribute
        if(this.hasAttribute('reversed')) {
            this.shadowRoot.lastElementChild.classList.add('reverse')
            this.shadowRoot.querySelector('.projDesc').classList.add('reverse')
            this.shadowRoot.querySelector('.projImage').classList.add('reverse')
            this.shadowRoot.querySelector('.description').classList.add('reverse')
            this.shadowRoot.querySelector('.softwareContainer').classList.add('reverse')
        }

        //Clear markup
        this.innerHTML = null
    }

}

window.customElements.define('featured-project-card', FeaturedProjectCard)