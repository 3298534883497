import { addThrottledListener } from '../utils'
import lottie from 'lottie-web'
import lottieData from './NavBarLottieData.json'

const template = document.createElement('template')
template.innerHTML = `
    <style>
        :host {
            --navHeight: 50px;
            --navBottomBorderHeight: 2px;
        }
        nav {
            position: relative;
            width: 100%;
            height: var(--navHeight);
            border-bottom: solid var(--navBottomBorderHeight) white;
            background-color: #5e5e5e;
            z-index: inherit;
        }
        nav a {
            display: block;
            color: #FFE54C;
            text-decoration: none;
            transition: color 0.5s;
        }
        nav a:hover {
            color: white;
        }
        .activeNavItem {
            color: white;
        }
        .hamburgerMenu {
            display: none;
            position: absolute;
            right: 3%;
            width: 40px;
            height: 40px;
            color: white;
            cursor: pointer;
        }
        .navItemsContainer {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            height: 100%;
        }
        @media screen and (max-width: 600px) {
            nav a:hover:not(.hamburgerMenu) {
                color: yellow;
            }
            .hamburgerMenu {
                display: block;
                top: 50%;
                transform: translateY(-50%);
            }
            .navItemsContainer {
                position: absolute;
                top: calc(var(--navHeight) + var(--navBottomBorderHeight));
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                height: auto;
                overflow: hidden;
                transform: scaleY(0);
                transform-origin: top;
                transition: transform 0.5s;
            }
            .navItemsContainer > * {
                box-sizing: border-box;
                width: 100%;
                padding: 10px 0 10px 20px;
                background-color: black;
            }
            .navItemsContainer.navExpanded {
                transform: scaleY(1);
            }
        }
    </style>
    <nav>
        <div class="navItemsContainer"></div>
        <a class="hamburgerMenu"></a>
    </nav>
`

class NavBar extends HTMLElement {

    constructor() {
        super()

        //Enable shadow DOM
        this.attachShadow({ mode: 'open' })
        this.shadowRoot.appendChild(template.content.cloneNode(true))

        //Set default display
        this.style.display = 'none'
        this.shadowRoot.lastElementChild.style.display = 'none'
        window.addEventListener('appReady', () => {
            this.style.display = 'block'
            this.shadowRoot.lastElementChild.style.display = 'block'
        })

        //Make nav bar on top of stacking context
        this.style.zIndex = '2147483647'

        //Set default width and height
        this.style.width = '100%'
        
        //Insert nav items
        this.shadowRoot.lastElementChild.firstElementChild.innerHTML = this.innerHTML

        //Clear markup
        this.innerHTML = null

        //Run interactivity code
        window.addEventListener('load', () => {
            this.#addMenuListener()
            this.#addStickyListener()
            this.#handleHighlight()
        })
    }

    #addMenuListener() {
        const navItemsContainer = this.shadowRoot.lastElementChild.firstElementChild
        const lottieContainer = this.shadowRoot.querySelector('.hamburgerMenu')
        const lottieAnim = lottie.loadAnimation({
            container: lottieContainer,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: lottieData
        })
        this.shadowRoot.querySelector('.hamburgerMenu').onclick = () => {
            if(navItemsContainer.className === 'navItemsContainer') {
                lottieAnim.setDirection(1)
                lottieAnim.play()
                navItemsContainer.classList.add('navExpanded')
            } else {
                lottieAnim.setDirection(-1)
                lottieAnim.play()
                navItemsContainer.classList.remove('navExpanded')
            }
        }
    }

    #addStickyListener() {
        let lastScrollPos = 0
        addThrottledListener(window, 'scroll', () => {
            lastScrollPos = window.scrollY
            if(lastScrollPos >= this.offsetTop) {
                if(this.shadowRoot.lastElementChild.style.position !== 'fixed') {
                    this.shadowRoot.lastElementChild.style.top = '0'
                    this.shadowRoot.lastElementChild.style.position = 'fixed'
                    this.nextElementSibling.style.marginTop = `${this.shadowRoot.lastElementChild.offsetHeight}px`
                }
            } else {
                if(this.nextElementSibling.style.marginTop !== '0') {
                    this.shadowRoot.lastElementChild.style.top = ''
                    this.shadowRoot.lastElementChild.style.position = ''
                    this.nextElementSibling.style.marginTop = '0'
                }
            }
        })
    }

    #handleHighlight() {
        let lastScrollPos = 0
        let activeNavItem = this.shadowRoot.lastElementChild.firstElementChild.childNodes[0]
        const navItems = Array.from(this.shadowRoot.lastElementChild.firstElementChild.children).splice(1, this.shadowRoot.lastElementChild.firstElementChild.children.length - 1)
        const sections = Array.from(navItems, function(node) { return document.querySelector(node.hash) })
        addThrottledListener(document, 'scroll', () => {
            lastScrollPos = window.scrollY
            if(this.shadowRoot.lastElementChild.style.position !== 'fixed') {
                activeNavItem.classList.remove('activeNavItem')
                activeNavItem = this.shadowRoot.lastElementChild.firstElementChild.firstElementChild
                activeNavItem.classList.add('activeNavItem')
                return
            }
            if(document.body.offsetHeight === lastScrollPos + window.innerHeight) {
                activeNavItem.classList.remove('activeNavItem')
                activeNavItem = this.shadowRoot.lastElementChild.firstElementChild.lastElementChild
                activeNavItem.classList.add('activeNavItem')
                return
            }
            const navBarBottom = lastScrollPos + this.shadowRoot.lastElementChild.offsetHeight
            for(let i = 0; i < sections.length; i++) {
                if(navBarBottom >= sections[i].offsetTop && navBarBottom < sections[i].offsetTop + sections[i].offsetHeight) {
                    activeNavItem.classList.remove('activeNavItem')
                    activeNavItem = navItems[i]
                    activeNavItem.classList.add('activeNavItem')
                    break
                }
            }
        })
    }

}

window.customElements.define('nav-bar', NavBar)