import anime from 'animejs'
import { supportsAVIF } from '../utils.js'

const template = document.createElement('template')
template.innerHTML = `
    <style>
        .invert {
            filter: invert(1) grayscale(1);
            mask-size: 7000% 100%;
            -webkit-mask-size: 7000% 100%;
            transition: mask-position 5s steps(69) 1.75s, -webkit-mask-position 5s steps(69) 1.75s;
        }
        .invert.animate {
            mask-position: 100% 0;
            -webkit-mask-position: 100% 0;
        }
        .photograph {
            width: 200px;
            height: 200px;
            border-width: 20px 20px 88px 20px;
            border-radius: 10px;
            border-color: white;
            border-style: solid;
        }
        .photographImage {
            position: relative;
            height: 100%;
            filter: blur(3px);
            transition: filter 5s 1.75s;
        }
        .photographImage.animate {
            filter: blur(0);
        }
        .photographImage > div {
            position: absolute;
            height: 100%;
            width: 100%;
            background-size: cover;
        }
        .signature {
            width: 90%;
            height: 90%;
        }
        .signature > svg {
            max-width: 100%;
            max-height: 100%;
        }
        .signatureContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 88px;
        }
        @media screen and (min-width: 1024px) {
            .photograph {
                width: 300px;
                height: 300px;
                border-width: 30px 30px 131px 30px;
                border-radius: 20px;
            }
            .signatureContainer {
                height: 131px;
            }
        }
        @media screen and (min-width: 1280px) {
            .photograph {
                width: 400px;
                height: 400px;
                border-width: 40px 40px 175px 40px;
                border-radius: 20px;
            }
            .signatureContainer {
                height: 175px;
            }
        }
    </style>
    <div class="photograph">
        <div class="photographImage">
            <div class="normal"></div>
            <div class="invert"></div>
        </div>
        <div class="signatureContainer">
            <div class="signature"></div>
        </div>
    </div>
`

class Photograph extends HTMLElement {

    #photographImage
    #photographMask
    #signature

    constructor() {
        super()
        this.attachShadow({ mode: 'open' })
        this.shadowRoot.appendChild(template.content.cloneNode(true))
        const mask = this.getAttribute('mask')
        const image = this.getAttribute('image')
        this.#photographImage = this.shadowRoot.querySelector('.photographImage')
        this.#photographMask = this.shadowRoot.querySelector('.invert')
        this.#signature = this.shadowRoot.querySelector('.signature')
        fetch(this.getAttribute('signature'))
        .then(res => res.text())
        .then(text => {
            this.#signature.innerHTML = text
            this.shadowRoot.querySelector('.signature > svg').id = 'target'
        })
        supportsAVIF(() => this.shadowRoot.querySelector('.normal').style.backgroundImage = this.#photographMask.style.backgroundImage = `url(${image}.avif)`, () => this.shadowRoot.querySelector('.normal').style.backgroundImage = this.#photographMask.style.backgroundImage = `url(${image}.webp)`)
        this.#photographMask.style.maskImage = `url('${mask}')`
        this.#photographMask.style.webkitMaskImage = `url('${mask}')`
        this.onanimationstart = () => {
            this.#photographImage.classList.add('animate')
            this.#photographMask.classList.add('animate')
            anime({
                targets: this.shadowRoot.querySelectorAll('#target path'),
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutQuad',
                duration: 3000,
                delay: function(_, i) {
                    return 2500 + (i * 2100)
                }
            })
        }
    }

}

window.customElements.define('signed-photograph', Photograph)