import App from './app/App.js'
import { addThrottledListener } from './utils.js'
import './components/NavBar.js'
import './components/Photograph.js'
import './components/ContactCard.js'
import './components/FeaturedProjectCard.js'


/* || Object to dispatch event when app and DOM are loaded and ready */
window.appReady = {}
const appReady = new Event('appReady')
Object.defineProperties(window.appReady, {
    appLoaded: {
        get: function() { return this._appLoaded },
        set: function(val) {
            this._appLoaded = val
            if(this._appLoaded && this._windowLoaded) window.dispatchEvent(appReady)
        }
    },
    windowLoaded: {
        get: function() { return this._windowLoaded },
        set: function(val) {
            this._windowLoaded = val
            if(this._appLoaded && this._windowLoaded) window.dispatchEvent(appReady)
        }
    }
})
window.addEventListener('load', function() { window.appReady.windowLoaded = true })


/* || declare window loaded listener */
window.addEventListener('appReady', function() {
    const el = document.getElementById('loadingScreen')
    el.style.opacity = '0'
    document.body.classList.remove('loading')
    setTimeout(function() { el.remove() }, 1000 * Number(getComputedStyle(document.documentElement).getPropertyValue('--loadingScreenFadeDuration').slice(0, -1)))
})


/* || Start canvas activity */
window.app = new App()
window.app.init(document.getElementById('welcomeButton'), document.getElementById('webgl'), document.getElementById('welcomeText'))


/* || Set up scoll in animations */
const items = Array.from(document.querySelectorAll('.scroll'))
const itemInView = (item, scrollOffset = 0) => item.getBoundingClientRect().top <= window.innerHeight - scrollOffset
function scrollListener() {
    for(let i = 0; i < items.length; i++) {
        if(itemInView(items[i], 225)) {
            items[i].style.animationPlayState = 'running'
            items.splice(i, 1)
            if(!items.length) return 0
        }
    }
    return 1
}
addThrottledListener(window, 'scroll', scrollListener, true)