import WebGLView from './WebGLView.js'
import { addThrottledListener } from '../utils.js'

export default class App {

    #webgl

    init(button, canvas, text) {
        this.#webgl = new WebGLView(canvas, button, text)
        addThrottledListener(window, 'resize', this.#webgl.resize.bind(this.#webgl))

        //Add this if resize doesn't get called on orientation change
        screen.orientation ? screen.orientation.addEventListener('change', () => this.#webgl.orientationUpdate = true) : window.addEventListener('orientationchange', () => this.#webgl.orientationUpdate = true)
        this.#animate()
    }

    #animate() {
        this.#webgl.update()
        this.#webgl.draw()
        requestAnimationFrame(this.#animate.bind(this))
    }

}