import * as Three from 'three'
import particleVertexShader from './shaders/vertex.glsl'
import particleFragmentShader from './shaders/fragment.glsl'
import configFile from './config.js'

export default class Particles {

    #points
    #texture

    constructor(scene, src) {
        new Three.TextureLoader().load(src, texture => {
            this.#texture = texture
            this.#initParticles(scene)
            window.appReady.appLoaded = true
        })
    }

    #initParticles(scene) {
        //Pixel data
        const pixelsData = []
        const numPixels = this.#texture.image.naturalWidth * this.#texture.image.naturalHeight
        
        //In-memory canvas
        const canvas = document.createElement('canvas')
        canvas.width = this.#texture.image.naturalWidth
        canvas.height = this.#texture.image.naturalHeight
        const context = canvas.getContext('2d')
        context.scale(1, -1)
        context.drawImage(this.#texture.image, 0, 0, this.#texture.image.naturalWidth, -this.#texture.image.naturalHeight)
        const colorData = context.getImageData(0, 0, canvas.width, canvas.height).data
        
        //Image processing
        for(let i = 0; i < numPixels; i++) {
            if(colorData[i * 4 + 3] !== 0) pixelsData.push(i % this.#texture.image.naturalWidth - (this.#texture.image.naturalWidth / 2), Math.floor(i / this.#texture.image.naturalWidth) - (this.#texture.image.naturalHeight / 2), 0)
        }
        
        //Buffer attributes
        const numPoints = pixelsData.length / 3
        const positions = new Float32Array(pixelsData.length)
        const durations = new Float32Array(numPoints)

        //Populate arrays
        for(let i = 0; i < numPoints; i++) {
            durations[i] = configFile.maxParticleAssembleTime - Math.random()
            
            positions[i * 3] = pixelsData[i * 3]
            positions[i * 3 + 1] = pixelsData[i * 3 + 1]
        }

        //Geometry
        const geometry = new Three.BufferGeometry()
        geometry.setAttribute('duration', new Three.BufferAttribute(durations, 1))
        geometry.setAttribute('position', new Three.BufferAttribute(positions, 3))

        //Material
        const material = new Three.ShaderMaterial({
            precision: 'mediump',
            transparent: true,
            vertexShader: particleVertexShader,
            fragmentShader: particleFragmentShader,
            uniforms: {
                uTime: { value: 0 },
                uSize: { value: 7 },
                uRadius: { value: this.#texture.image.naturalWidth / 2 },
                uTexture: { value: this.#texture },
                uAssemble: { value: 0 },
                uAssembleTime: { value: 0 },
                uTextureSize: { value: new Three.Vector2(this.#texture.image.naturalWidth, this.#texture.image.naturalHeight) }
            },
            defines: {
                PI: Math.PI
            }
        })

        //Points
        scene.add(this.#points = new Three.Points(geometry, material))
    }

    assembleImage(cb) {
        if(this.#points.material.uniforms.uAssemble.value !== 1) {
            this.#points.material.uniforms.uAssemble.value = 1
            this.#points.material.uniforms.uAssembleTime.value = this.#points.material.uniforms.uTime.value
            window.setTimeout(cb, configFile.maxParticleAssembleTime * 1000)
        }
    }
    update(value) {
        if(this.#points) this.#points.material.uniforms.uTime.value = value
    }

}